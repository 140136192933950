import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { LazyBlocksFactory } from '../components/LazyBlocksFactory/LazyBlocksFactory'
interface Props {
  data: any
  pageContext: any
}
const MeetTheTeam = ({ data, pageContext }: Props) => {
  const { wpPage } = data

  // const lazyBlocks = parse(wpPage.lazy_data)
  const renderedBlocks = LazyBlocksFactory(wpPage.lazy_data, wpPage.title)
  return <Layout>{renderedBlocks.map((v) => v)}</Layout>
}
export default MeetTheTeam

export const pageQuery = graphql`
  query meetTheTeam {
    wpPage(title: { eq: "Meet The Team" }) {
      id
      lazy_data
      title
    }
  }
`
